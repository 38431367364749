* {
  box-sizing: border-box;
}

html {
  --eneftigo-text-font-family: "Helvetica", "Arial", sans-serif;
  /* --eneftigo-header-font-family: "Helvetica", "Arial", sans-serif; */
  --eneftigo-header-font-family: "Jura", sans-serif;
  --eneftigo-mono-font-family: "Syne Mono", monospace;

  --eneftigo-black: #111111;
  --eneftigo-very-dark-grey: #202020;
  --eneftigo-dark-grey: #262626;
  --eneftigo-white: #FFFFFF;
  --eneftigo-red: #DF001A;
  --eneftigo-yellow: #DDAA33;
  --eneftigo-green: rgb(80, 190, 112);
  --eneftigo-grey: grey;
  --eneftigo-blue: #3499c4;
  --eneftigo-magenta: #282893;

  --eneftigo-background-color: var(--eneftigo-black);
  --eneftigo-text-color: var(--eneftigo-white);
  --eneftigo-fill-color: var(--eneftigo-magenta);
  --eneftigo-button-color: var(--eneftigo-yellow);

  --eneftigo-font-size-small: calc(11px);

  --gray: #555;
  --light-gray: #ccc;
  --shadow: #e6e6e6;
  --success: rgb(90, 206, 132);
  --primary: #AA005D;
  --secondary: #DD3333;
  --cancel: #5555AA;

  background-color: var(--eneftigo-black);
  background-image: url(assets/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: var(--eneftigo-white);
  font-family: var(--eneftigo-text-font-family);
  font-size: 16px;
  line-height: 1.3;
}

.site-title {
  font-size: 2rem;
  padding-left: 6px;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

main {
  margin: 0 auto;
  max-width: 26em;
}

.signin {
  background-image: url(assets/eneftigo_logo.png);
}

h1 {
  background-position: center 1em;
  background-repeat: no-repeat;
  background-size: auto 1.5em;
  margin-top: 0;
  padding: 3.5em 0 0;
  text-align: center;
  font-size: 1.5em;
}

.greeting {
  color: var(--secondary);
  text-decoration: underline;
}

h2 {
  text-align: center;
}

.please-wait .change {
  pointer-events: none;
}

ul.information {
  margin: 2em 0 2em 0;
  padding: 0;
  text-align: left;
  font-size: 0.8em;

}

.information li:first-child {
  border-top: 1px solid var(--light-gray);
}

.information li {
  padding: 0.5em 0;
  border-bottom: 1px solid var(--light-gray);
  list-style: none;
}

.change {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: space-evenly;
  align-items: stretch;
  font-size: 1em;
  border: 2px solid var(--light-gray);
  padding: 0.5em;
}

.change>div {
  display: flex;
  align-content: stretch;
  justify-content: space-evenly;
  align-items: stretch;
}

.change input {
  flex: 1;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.change label {
  display: block;
  text-align: left;
  margin-right: 10px;
  padding-bottom: 0.5em;
}

.change button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

a,
.link {
  color: var(--primary);
  text-decoration: none;
}

a:hover,
a:focus,
.link:hover,
.link:focus {
  text-decoration: underline;
}

a:active,
.link:active {
  color: var(--secondary);
}

button,
input {
  font-family: var(--eneftigo-mono-font-family);
  font-size: calc(0.5em + 0.5vw);
  outline: none;
}

main.please-wait .change button {
  position: relative;
  pointer-events: none;
  background-color: white;
}

main.please-wait .change button span {
  visibility: hidden;
}

button {
  background-color: var(--eneftigo-button-color);
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--eneftigo-black);
  color: var(--eneftigo-black);
  cursor: pointer;
  /* padding: 0.3em 0.75em; */
  transition: transform 30ms;
  font-size: 14px;
  padding: 4px 8px;
}

button:hover {
  box-shadow: 0 0 10em rgba(255, 255, 255, 0.2) inset;
}

button:disabled {
  background-color: var(--eneftigo-grey);
}

input {
  background-color: var(--light-gray);
  border: none;
  border-radius: 5px 0 0 5px;
  caret-color: var(--eneftigo-white);
  color: var(--eneftigo-white);
  padding: 0.25em 0.5em;
}

input::selection {
  background-color: var(--secondary);
  color: var(--eneftigo-white);
}

input:focus {
  box-shadow: 0 0 10em rgba(0, 0, 0, 0.02) inset;
}

code {
  color: var(--gray);
}

li {
  padding-bottom: 1em;
}

@media (prefers-color-scheme: dark) {
  html {
    --bg: #1e1e1e;
    --fg: #efefef;
    --gray: #aaa;
    --shadow: #2a2a2a;
    --light-gray: #444;
  }

  h1 {
    background-image: url(assets/logo_white.svg);
  }

  input:focus {
    box-shadow: 0 0 10em rgba(255, 255, 255, 0.02) inset;
  }
}

main.please-wait .loader,
main.please-wait .loader:after {
  display: inline-block;
}

.loader,
.loader:after {
  display: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.loader {
  font-size: 10px;
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  text-indent: -9999em;
  border-top: 3px solid var(--secondary);
  border-right: 3px solid var(--secondary);
  border-bottom: 3px solid var(--secondary);
  border-left: 3px solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.container {
  margin: 1rem 1rem;
  text-align: center;
  overflow: hidden;
}

/* Navbar */

.nav {
  background-color: var(--eneftigo-very-dark-grey);
  color: var(--eneftigo-white);
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  gap: 2rem;
  padding: 0 0.25rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
  color: var(--eneftigo-white);
}

.nav button {
  padding: 5;
  margin: 10;
  list-style: none;
  display: inline;
  gap: 1rem;
}

.nav a {
  color: var(--eneftigo-white);
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  font-family: var(--eneftigo-header-font-family);
  /* font-size: 17px; */
  /* padding: .25rem; */
}

.nav li {
  /* border-top-left-radius: 3;
  border-top-right-radius: 3; */
  /* padding-bottom: 12px; */
  /* padding-top: 12px; */
  /* margin-top: 0px; */
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 0px;
}

/* .nav li.active {
  background-color: #var;
} */

.nav li:hover {
  background-color: var(--eneftigo-very-dark-grey);
  /* border-bottom: 0; */
}


/* Modal */

.modal-header {
  background-color: var(--eneftigo-fill-color);
  margin-block-start: "0px";
  margin-block: "0px";
  margin: "0px";
  color: var(--eneftigo-text-color);
}

/* Listing card content */

.flex-container {
  /* color: var(--fg); */
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin: 0px;
  padding: 0px;
}

.flex-container>div {
  margin: 0px;
  padding: 0px;
  font-size: 20px;
}

.flex-container>.flex-item {
  flex: auto;
}

.card-line {
  margin-block-start: 2px;
  margin-block-end: 2px;
}

.proposal_price {
  background-color: white;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  width: 100px;
}

.listing_card_content {
  border-color: var(--eneftigo-fill-color);
  border-radius: 1em;
  border-width: 1px;
  border-style: solid;
  background-color: var(--eneftigo-background-color);
}

.listing_card_info {
  background-color: var(--eneftigo-fill-color);
  color: var(--eneftigo-text-color);
}

.listing_card p {
  font-size: 12px;
  color: var(--eneftigo-text-color);
  margin-block-start: 0.4em;
  margin-block-end: 0.4em;
}




#listing_title_thumb {
  font-size: 14px;
  inset: 1px;
  padding-bottom: 2px;
  margin-block-start: 0;
  margin-block-end: 0;
}

.nft_card {
  color: white;
  background-color: var(--eneftigo-dark-grey);
  border-radius: 3;
}

.nft_card p {
  font-size: 12px;
  color: white;
  margin-block-start: 0.4em;
  margin-block-end: 0.4em;
}

.cancel {
  background-color: var(--cancel);
}

#overlay {
  position: fixed;
  /* Sit on top of the page content */
  display: none;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
}

.price p {
  font-size: 17px;
}

.error {
  font-size: 12px;
  color: grey;
}

.action-button,
.action-button-destructive {
  color: white;
  border-radius: 4px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  background: var(--eneftigo-button-color);
}

.action-button-destructive {
  background: var(--eneftigo-red);
}

.new_listing_card {
  opacity: 0.25;
  scale: 1.0;
}

.new_listing_card:hover {
  opacity: 0.75;
  scale: 1.02;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: #18184a;
  color: var(--eneftigo-text-color);
  border-radius: '0px';
}

@keyframes slidein {
  from {
    margin-left: 100%;
    opacity: 50%;
  }

  to {
    margin-left: 0%;
    opacity: 100%;
  }
}

@keyframes fadein {
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
}